body {
    font-family: 'Varela Round', sans-serif;
    color: #4d4d4d;
}

img {
    @include object-fit(cover, center);
}

/* Links */

a {
    color: #ccc;
    text-decoration: none;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    outline: none;
    border: 0px;
}

p {
    font-family: 'Nunito', sans-serif;
}

/* Navbar */

.navbar {
    background-color: none !important;
}

.nav-link {
    color: #ffffff !important;
    font-weight: 600;
    &:hover {
        color: #ccc !important;
    }
    &:active {
        color: #ccc;
    }
}

.navbar-social a {
    color: #111f44;
    &:hover {
        color: #fff;
    }
}

.active {
    color: #ccc !important;
    &:active,
    &:hover,
    &:visited {
        color: #ccc !important;
    }
}

/*Borders*/

.border-primary {
    border-color: #ccc !important;
}

/* Carousel */

.carousel-caption {
    bottom: 2rem;
    padding-bottom: 0rem;
}

.carousel-indicators [data-bs-target] {
    background-color: #000;
}

/* Backgrounds */

.bg-cover {
    background-color: #111f44 !important;
    background-image: url('/dist/images/bg-blue.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
}

.bg-cover-gray {
    background-color: #111f44 !important;
    background-image: url('/dist/images/bg-gray.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
}

.bg-orange {
    background-color: #ff583d !important;
}

.bg-primary {
    background-color: #111f44 !important;
}

.bg-secondary {
    background-color: #0c1735 !important;
}

.bg-light {
    background-color: #e7e7e7 !important;
}

.bg-blue-texture {
    background-color: #111f44 !important;
    background-image: url('/dist/images/tapiz.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: 60%;
}

/* Accordion */

.accordion-button:not(.collapsed) {
    color: #000;
    font-weight: bold;
    background-color: #ECECEC;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

/*Text*/

.text-primary {
    color: #111f44 !important;
}

.text-gray {
    color: #ccc !important;
}

.text-orange {
    color: #ff583d !important;
}

/*Btns*/

.btn-primary {
    background-color: #111f44;
    border: 0px;
    border-radius: 4px;
}

.btn-orange {
    background-color: #ff583d;
    border: 0px;
    border-radius: 4px;
}

/*Cards*/

.card-img-overlay {
    pointer-events: none;
}

.card-footer {
    background-color: transparent;
    border-top: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0px !important;
    border: 0px !important;
}

/* Form */

#results,
#error,
#success,
#loading {
    display: none;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    > {
        .col,
        [class*="col-"] {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
}

.form-group {
    margin-bottom: 1rem;
}

.footer a {
    color: #ffffff;
    &:hover {
        color: #ff583d;
    }
}